import * as Redux from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { addOrganization } from "../../../hubs/UserAccountManagementController.hub";
import { getSuppliers } from "../../../hubs/SuppliersController.hub";
import { Organization, OrganizationTransient, LineOfBusiness } from "../models";
import { Supplier } from "../models";

export interface SliceProps {
    status: "initial" | "loading" | "loaded" | "saving" | "saved" | "error",
    organization: Organization | null,
    suppliers: Supplier[],
    linesOfBusiness: string[],
    supplierStatus: "initial" | "loading" | "loaded" | "error"
}

export function makeInitialState(): SliceProps {
    return {
        status: "initial",
        organization: null,
        suppliers: [],
        linesOfBusiness: [],
        supplierStatus: "initial"
    };
}

export const thunks = {
    addOrganization: createAsyncThunk(
        "organizations/addOrganization",
        async (organization: OrganizationTransient) => addOrganization(organization)
    ),
    getSuppliers: createAsyncThunk(
        "organizations/getSuppliers",
        async (lineOfBusiness: LineOfBusiness) => getSuppliers(lineOfBusiness)
    ),
}

export const slice = Redux.createSlice({
    name: "addOrganization",
    initialState: makeInitialState(),
    reducers: {
        "setStatus": (state: SliceProps, action: { payload: "initial" | "saving" | "saved" | "error" }) => {
            state.status = action.payload;
        },
        getLinesOfBusiness: (state: SliceProps, action: { payload: any[] }) => {
            const lobs = action.payload
                .filter(claim => claim.type === "https://auth.directsupply.com/lines_of_business")
                .flatMap(claim => claim.value);
            state.linesOfBusiness = lobs;
        },
    },
    extraReducers: builder => {
        builder.addCase(thunks.addOrganization.pending, (state) => {
            state.status = "saving";
        });
        builder.addCase(thunks.addOrganization.fulfilled, (state, action) => {
            if (action.payload.status === "2xx") {
                state.organization = action.payload.organization;
                state.status = "saved";
            }
            else {
                state.status = "error";
            }
        });
        builder.addCase(thunks.addOrganization.rejected, (state) => {
            state.status = "error";
        });
        builder.addCase(thunks.getSuppliers.pending, (state) => {
            state.supplierStatus = "loading";
            state.suppliers = [];
        });
        builder.addCase(thunks.getSuppliers.fulfilled, (state, action) => {
            if (action.payload.status === "2xx" && action.payload.suppliers) {
                state.suppliers = action.payload.suppliers;
                state.supplierStatus = "loaded";
            } else {
                state.supplierStatus = "error";
                state.suppliers = [];
            }
        });
        builder.addCase(thunks.getSuppliers.rejected, (state, action) => {
            state.supplierStatus = "error";
            state.suppliers = [];
        });
    }
});

