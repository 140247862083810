import {
    Button,
    Card,
    DsxLoadingSpinner,
    MdArrowBack,
    MdCheck,
    MdClose,
    MdError,
    MultiSearchSelectInput,
    StandaloneIcon,
    Text,
    useToast,
} from "@dsx/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../../auth/auth-context";
import { getStore } from "../../../../store";
import { PageProps, useSlice } from "../../../../util/page";
import { UpdateOrganizationMemberRequest } from "../../models";
import {
    slice,
    thunks,
    selectHasChanges,
    selectChangesAreValid,
} from "./edit-organization-user.slice";
import siteStyles from "../../../site.module.css";

export function EditOrganizationMember(props: PageProps<"editOrganizationMember">) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const popToast = useToast();
    const { userClaims } = useAuth();
    const { organizationId, userId } = useParams<{ organizationId: string, userId: string }>();

    React.useEffect(() => {
        loadOrganizationMember();
    }, [userClaims, organizationId, userId, dispatch]);

    const [rolesBlurred, setRolesBlurred] = React.useState(false);

    const hasChanges = useSelector(selectHasChanges);
    const changesAreValid = useSelector(selectChangesAreValid);

    const loadOrganizationMember = async () => {
      if (userClaims) {
          dispatch(slice.actions.setEditingUserClaims(userClaims));
      }

      if (organizationId && userId) {
          dispatch(thunks.getOrganizationMember({ organizationId, userId }));
          dispatch(thunks.getRoles());
      }
    }

    const updateOrganizationMember = async () => {
        if (!organizationId || !userId)
            return;

        try {
            const organizationMember = getStore().getState().editOrganizationMember.organizationMember;

            const request: UpdateOrganizationMemberRequest = {
                roles: organizationMember!.roles,
            };

            await dispatch(thunks.updateOrganizationMember({ organizationId, userId, request}));

            const status = getStore().getState().editOrganizationMember.status;
            
            if (status === "saved") {
                popToast(
                    <span>
                        User <b>{props.organizationMember!.name}</b> updated.
                    </span>, {
                        title: "User updated",
                        variant: "success",
                        position: "top-center",
                        autoClose: 5000,
                    }
                );

                navigate(-1);

                return;
            }
        }
        catch (error) {
            console.log(error);
        }

        popToast(
            <span>
                An error occurred while updating user <b>{props.organizationMember!.name}</b>. Please try again.
            </span>, {
                title: "Error updating user",
                variant: "danger",
                position: "top-center",
                autoClose: false,
            }
        );
    };

    return (
        <div className={siteStyles["block-container"]}>
            <Card>
                <Text variant="headline-1">Edit User</Text>
                <br></br>
                <Text>
                    Please update the details below for the user within the organization.
                </Text>
            </Card>
            <Card>
                {props.status === "loading" && (
                    <div className={siteStyles["loading-container"]}>
                        <StandaloneIcon
                            icon={DsxLoadingSpinner}
                            title={"Loading user..."}
                            size="size-1100"
                        />
                    </div>
                )}
                {props.status === "loadError" && (
                    <div className={siteStyles["icon-block-container"]}>
                        <div className={siteStyles["centered-container"]}>
                            <StandaloneIcon
                                icon={MdError}
                                title="Error loading user"
                                size="size-1100"
                            />
                        </div>
                        <div className={siteStyles["centered-container"]}>
                            <Text variant="body">Error loading user</Text>
                        </div>
                    </div>
                )}
                {(props.status === "loaded" || props.status === "saving" || props.status === "saved" || props.status === "saveError") && (
                    <div className={siteStyles["card-content"]}>
                        <div>
                            <label htmlFor="name">Name</label>
                            <input
                                type="text"
                                name="name"
                                value={props.organizationMember!.name}
                                readOnly
                                disabled
                                className={siteStyles["readonly-input"]}
                            />
                        </div>
                        <div>
                            <label htmlFor="email">Email</label>
                            <input
                                type="email"
                                name="email"
                                value={props.organizationMember!.email}
                                readOnly
                                disabled
                                className={siteStyles["readonly-input"]}
                            />
                        </div>
                        <div>
                            <label htmlFor="roles">Roles</label>
                            <MultiSearchSelectInput
                                name="roles"
                                required={true}
                                variant={rolesBlurred && props.organizationMember!.roles.length === 0 ? "danger" : "default"}
                                value={props.organizationMember!.roles}
                                options={props.allRoles.map(role => ({ value: role, label: role }))}
                                onChange={selectedValues => dispatch(slice.actions.updateOrganizationMemberRoles(selectedValues))}
                                onBlur={() => setRolesBlurred(true)}
                                disabled={props.status === "saving"}
                            />
                        </div>
                    </div>
                )}
                <div className={siteStyles["button-container"]}>
                    {(props.status === "loaded" || props.status === "saving" || props.status === "saveError") && (
                        <div>
                            <Button
                                variant="primary"
                                icon={MdCheck}
                                disabled={!changesAreValid || !hasChanges || props.status === "saving"}
                                onClick={updateOrganizationMember}
                                loadingState={props.status === "saving" ? "loading" : "initial"}
                            >
                                Update User
                            </Button>
                            <Button
                                variant="ui-control"
                                icon={MdClose}
                                disabled={props.status === "saving"}
                                onClick={() => loadOrganizationMember()}
                            >
                                Discard
                            </Button>
                        </div>
                    )}
                    <div className={siteStyles["back-button"]}>
                        <Button
                            variant="ui-control"
                            icon={MdArrowBack}
                            disabled={props.status === "saving"}
                            onClick={() => navigate(-1)}
                        >
                            Back
                        </Button>
                    </div>
                </div>
            </Card>
        </div>
    );
}

export function UpdateOrganizationMemberConnected() {
    const storeProps = useSlice("editOrganizationMember");
    return <EditOrganizationMember {...storeProps} />;
}
