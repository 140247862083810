import * as Redux from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { addOrganizationInvitation, getRoles } from "../../../../hubs/UserAccountManagementController.hub";
import { OrganizationInvitationTransient } from "../../models";

export interface SliceProps {
    status: "initial" | "loading" | "loaded" | "loadError" | "saving" | "saved" | "saveError",
    roles: string[] | undefined,
}

export function makeInitialState(): SliceProps {
    return {
        status: "initial",
        roles: [],
    };
}

export const thunks = {
    addOrganizationInvitation: createAsyncThunk(
        "organizationInvitations/addOrganizationInvitation",
        async ({ organizationId, invitation }: { organizationId: string, invitation: OrganizationInvitationTransient }) => addOrganizationInvitation(organizationId, invitation)
    ),
    getUserRoles: createAsyncThunk(
        "organizationInvitations/getRoles",
        async () => getRoles()
    )
}

export const slice = Redux.createSlice({
    name: "addInvitation",
    initialState: makeInitialState(),
    reducers: {  
        setStatus: (state: SliceProps, action: { payload: "initial" | "loading" | "loaded" | "loadError" | "saving" | "saved" | "saveError" }) => {
            state.status = action.payload
        },
    },
    extraReducers: builder => {
        builder.addCase(thunks.getUserRoles.pending, (state) => {
            state.status = "loading";
        });

        builder.addCase(thunks.getUserRoles.fulfilled, (state, action) => {
            if (action.payload.status === "2xx") {
                state.roles = action.payload.roles;
                state.status = "loaded";
            } else {
                state.status = "loadError";
            }
        });

        builder.addCase(thunks.getUserRoles.rejected, (state) => {
            state.status = "loadError";
        });

        builder.addCase(thunks.addOrganizationInvitation.pending, (state) => {
            state.status = "saving";
        });

        builder.addCase(thunks.addOrganizationInvitation.fulfilled, (state, action) => {
            state.status = action.payload.status === "2xx" ? "saved" : "saveError";
        });

        builder.addCase(thunks.addOrganizationInvitation.rejected, (state) => {
            state.status = "saveError";
        });
    }
});