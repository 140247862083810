import {
    Button,
    Card,
    MdAdd,
    MdArrowBack,
    MdClose,
    Text,
    TextInput,
    SelectField,
    useToast,
    SearchSelectInput,
    LoadingDataState,
    MdError,
} from "@dsx/react";
import { useAuth } from "../../../auth/auth-context";

import * as React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getStore } from "../../../store";
import { PageProps, useSlice } from "../../../util/page";
import siteStyles from "../../site.module.css";
import { slice, thunks } from "./add-organization.slice";
import { LineOfBusiness, OrganizationType } from "../models";

export function AddOrganization(props: PageProps<"addOrganization">) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const popToast = useToast();
    const { userClaims } = useAuth();

    const [organizationName, setOrganizationName] = React.useState("");
    const [organizationDisplayName, setOrganizationDisplayName] = React.useState("");
    const [organizationNameBlurred, setOrganizationNameBlurred] = React.useState(false);
    const [organizationDisplayNameBlurred, setOrganizationDisplayNameBlurred] = React.useState(false);
    const [selectedLOB, setSelectedLOB] = React.useState<string>("");
    const [selectedSupplier, setSelectedSupplier] = React.useState<string>("");
    const [supplierSearchText, setSupplierSearchText] = React.useState("");

    const loadingState: { initial: LoadingDataState; loading: LoadingDataState; loaded: LoadingDataState; error: LoadingDataState } = {
        initial: "initial",
        loading: "loading",
        loaded: "success",
        error: "failure",
    };

    React.useEffect(() => {
        if (userClaims) {
            dispatch(slice.actions.getLinesOfBusiness(userClaims));
        }
    }, [userClaims, dispatch]);

    const organization = {
        name: organizationName,
        displayName: organizationDisplayName,
        organizationType: OrganizationType.Supplier,
        supplier: {
            code: selectedSupplier,
            lineOfBusiness: selectedLOB as LineOfBusiness
        }
    };

    const organizationNameIsValid = organization.name.length >= 3 && organization.name.length <= 50
        && /^[a-z0-9-_]+$/.test(organization.name)
        && /^[a-z0-9]/.test(organization.name)
        && /[a-z0-9]$/.test(organization.name);
    const organizationDisplayNameIsValid = organization.displayName.length > 0 && organization.displayName.length <= 255;
    const organizationIsValid = organizationNameIsValid && 
        organizationDisplayNameIsValid && 
        selectedLOB.length > 0 &&
        selectedSupplier.length > 0;

    const formatOrganizationName = (name: string) => {
        return name.replace(/[^a-zA-Z0-9-_]/g, "").substr(0, 50).toLowerCase();
    };

    const reset = () => {
        setOrganizationName("");
        setOrganizationDisplayName("");
        setOrganizationNameBlurred(false);
        setOrganizationDisplayNameBlurred(false);
        setSelectedLOB("");
        setSelectedSupplier("");
        dispatch(slice.actions.setStatus("initial"));
    };

    const addOrganization = async () => {
        if (!organizationIsValid) return;

        try {
            await dispatch(thunks.addOrganization(organization));
            const state = getStore().getState().addOrganization;
            const status = state.status;
            const createdOrganization = state.organization;
            if (status === "saved" && createdOrganization) {
                popToast(
                    <span>
                        Organization <b>{createdOrganization.displayName}</b> added.{" "}
                        <a href={`/user-account-management/organizations/${createdOrganization.id}/users`}>
                            View users
                        </a>
                    </span>, {
                        title: "Organization Added",
                        variant: "success",
                        position: "top-center",
                        autoClose: 5000,
                    }
                );

                reset();
                return;
            }
        }
        catch (error) {
            console.error("Error adding organization:", error);
        }

        popToast(
            <span>
                An error occurred while adding organization <b>{organization.displayName}</b>. Please try again.
            </span>, {
                title: "Error adding organization",
                variant: "danger",
                position: "top-center",
                autoClose: false,
            }
        );
    };

    const filteredSuppliers = supplierSearchText.length > 0
        ? props.suppliers.filter(supplier => 
            supplier.code.toLowerCase().includes(supplierSearchText.toLowerCase()) ||
            supplier.name.toLowerCase().includes(supplierSearchText.toLowerCase())
        )
        : props.suppliers;

    return (
        <div className={siteStyles["block-container"]}>
            <Card>
                <Text variant="headline-1">Add Organization</Text>
                <br />
                <Text>
                    Please provide the organization details below.
                </Text>
            </Card>
            <Card>
                <div className={siteStyles["card-content"]}>
                <div>
                        <label htmlFor="supplier-lob">Line of Business</label>
                        <SelectField
                            name="organization-lob"
                            required={true}
                            options={[
                                { value: '', label: 'Select a Line of Business' },
                                ...props.linesOfBusiness.map(lob => ({
                                    value: lob,
                                    label: lob
                                }))
                            ]}
                            value={selectedLOB || ''}
                            onChange={async (e) => {
                                const selected = e.target.value;
                                setSelectedLOB(selected);
                                if (selected) {
                                    await dispatch(thunks.getSuppliers(selected as LineOfBusiness));
                                }
                            }}
                            disabled={props.status === "saving"} 
                            label={undefined}
                        />
                    </div>                    
                    <div>
                        <label htmlFor="supplier">Supplier</label>
                        <SearchSelectInput
                            id="supplier"
                            required={true}
                            options={[
                                { 
                                    value: '', 
                                    label: 'Select a Supplier'
                                },
                                ...filteredSuppliers.map(supplier => ({
                                    value: supplier.code,
                                    label: `${supplier.code} | ${supplier.name}`
                                }))
                            ]}
                            value={selectedSupplier}
                            loadingState={loadingState[props.supplierStatus]}
                            variant={props.supplierStatus === "error" ? "danger" : "default"}
                            onChange={value => {
                                const selectedSupplier = props.suppliers.find(s => s.code === value);
                                setSelectedSupplier(value);

                                if (selectedSupplier) {
                                    const formattedName = formatOrganizationName(value);
                                    setOrganizationName(formattedName);
                                    setOrganizationNameBlurred(true);
                                    setOrganizationDisplayName(selectedSupplier.name);
                                    setOrganizationDisplayNameBlurred(true);
                                } else {
                                    setOrganizationName("");
                                    setOrganizationDisplayName("");
                                }
                            }}
                            onSearch={setSupplierSearchText}
                            searchFn={(options, search) => options.filter(option => 
                                option.label.toLowerCase().includes(search.toLowerCase())
                            )}
                            disabled={props.status === "saving" || !selectedLOB || props.supplierStatus !== "loaded"}
                        />
                        {props.supplierStatus === "error" && (
                            <Text
                                icon={MdError}
                                variant="body-compact-small"
                                color="color-red"
                            >
                                Failed to load suppliers.
                            </Text>
                        )}
                    </div>
                    <div>
                        <label htmlFor="organization-name">Name</label>
                        <Text variant="label-addendum">(i.e. Supplier Code)</Text>
                        <TextInput
                            name="organization-name"
                            required={true}
                            minLength={3}
                            maxLength={50}
                            variant={organizationNameBlurred && !organizationNameIsValid ? "danger" : "default"}
                            readOnly={props.status === "saving" || !selectedSupplier}
                            disabled={!selectedSupplier}
                            value={organizationName}
                            onChange={e => setOrganizationName(formatOrganizationName(e.target.value))}
                            onBlur={() => setOrganizationNameBlurred(true)}
                        />
                    </div>
                    <div>
                        <label htmlFor="organization-display-name">Display Name</label>
                        <Text variant="label-addendum">(i.e. Supplier Name)</Text>
                        <TextInput
                            name="organization-display-name"
                            required={true}
                            maxLength={30}
                            variant={organizationDisplayNameBlurred && !organizationDisplayNameIsValid ? "danger" : "default"}
                            readOnly={props.status === "saving" || !selectedSupplier}
                            disabled={!selectedSupplier}
                            value={organizationDisplayName}
                            onChange={e => setOrganizationDisplayName(e.target.value.trimStart())}
                            onBlur={() => setOrganizationDisplayNameBlurred(true)}
                        />
                    </div>
                </div>
                <div className={siteStyles["button-container"]}>
                    <Button
                        variant="primary"
                        icon={MdAdd}
                        disabled={!organizationIsValid || props.status === "saving"}
                        loadingState={props.status === "saving" ? "loading" : "initial"}
                        onClick={addOrganization}
                    >
                        Add Organization
                    </Button>
                    <Button
                        variant="ui-control"
                        icon={MdClose}
                        disabled={props.status === "saving"}
                        onClick={() => reset()}
                    >
                        Discard
                    </Button>
                    <div className={siteStyles["back-button"]}>
                        <Button
                            variant="ui-control"
                            icon={MdArrowBack}
                            disabled={props.status === "saving"}
                            onClick={() => navigate(-1)}
                        >
                            Back
                        </Button>
                    </div>
                </div>
            </Card>
        </div>
    );
}

export function AddOrganizationConnected() {
	const storeProps = useSlice("addOrganization");
	return <AddOrganization {...storeProps} />;
}
