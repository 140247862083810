import { http } from "./hub-utils";
import { LineOfBusiness, Supplier } from "../user-account-management/organizations/models";

export async function getSuppliers(lineOfBusiness: LineOfBusiness) {
    const response = await http.get<Supplier[]>("/api/suppliers?lineOfBusiness=" + lineOfBusiness);

    return response.status === "2xx" ? {
        suppliers: response.body,
        status: response.status
    } : {
        status: response.status
    };
}
