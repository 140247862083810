export interface OrganizationTransient {
    name: string;
    displayName: string;
    organizationType: OrganizationType;
    supplier?: OrganizationSupplierMetadata;
}

export interface OrganizationSupplierMetadata {
    code: string;
    lineOfBusiness: LineOfBusiness;
}

export enum OrganizationType {
    Undefined = "Undefined",
    Supplier = "Supplier"
}

export interface Organization extends OrganizationTransient {
	id: string;
}

export interface OrganizationMember {
    userId: string;
    name: string;
    email: string;
    roles: string[];
}

export interface OrganizationInvitationTransient {
    inviteeEmail: string;
    roles: string[];
}

export interface OrganizationInvitation extends OrganizationInvitationTransient {
    id: string;
    organizationId: string;
    inviterName: string;
    createdAt: Date;
    expiresAt: Date;
}

export interface UpdateOrganizationMemberRequest {
    roles: string[];
}

export interface Supplier {
    code: string;
    name: string;
}

export enum LineOfBusiness {
    DSE = "DSE",
    DSSI = "DSSI",
    TELS = "TELS"
}
