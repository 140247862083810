import * as Redux from "@reduxjs/toolkit";
import { createAsyncThunk, createSelector } from "@reduxjs/toolkit";
import { getOrganizationMember, getRoles, updateOrganizationMember } from "../../../../hubs/UserAccountManagementController.hub";
import { OrganizationMember, UpdateOrganizationMemberRequest } from "../../models";
import { LoadingStatus } from "../../../../slices.d";
import { RootState } from "../../../../store";
import { UserClaim } from "../../../../auth/auth-context.d";

export interface SliceProps {
    status: "initial" | "loading" | "loaded" | "loadError" | "saving" | "saved" | "saveError",
    organizationMemberLoadingStatus: LoadingStatus,
    rolesLoadingStatus: LoadingStatus,
    organizationMember: OrganizationMember | null,
    originalOrganizationMember: OrganizationMember | null,
    allRoles: string[],
    editingUserClaims: UserClaim[],
}

export function makeInitialState(): SliceProps {
    return {
        status: "initial",
        organizationMemberLoadingStatus: "pending",
        rolesLoadingStatus: "pending",
        organizationMember: null,
        originalOrganizationMember: null,
        allRoles: [],
        editingUserClaims: [],
    };
}

export const thunks = {
    getOrganizationMember: createAsyncThunk(
        "organizationMembers/getOrganizationMember",
        async ({ organizationId, userId }: { organizationId: string, userId: string }) => getOrganizationMember(organizationId, userId)
    ),
    getRoles: createAsyncThunk(
        "organizationMembers/getRoles",
        async () => getRoles()
    ),
    updateOrganizationMember: createAsyncThunk(
        "organizationMembers/updateOrganizationMember",
        async ({ organizationId, userId, request }: { organizationId: string, userId: string, request: UpdateOrganizationMemberRequest }) => updateOrganizationMember(organizationId, userId, request)
    ),
}

export const slice = Redux.createSlice({
    name: "editOrganizationMember",
    initialState: makeInitialState(),
    reducers: {
        setEditingUserClaims: (state, action: Redux.PayloadAction<UserClaim[]>) => {
            state.editingUserClaims = action.payload;
        },
        updateOrganizationMemberRoles: (state, action: Redux.PayloadAction<string[]>) => {
            state.organizationMember!.roles = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(thunks.getOrganizationMember.pending, (state) => {
            state.organizationMemberLoadingStatus = "loading";

            setStatus(state, "loading");
        });

        builder.addCase(thunks.getOrganizationMember.fulfilled, (state, action) => {
            if (action.payload.status === "2xx") {
                state.organizationMember = action.payload!.organizationMember;
                state.originalOrganizationMember = JSON.parse(JSON.stringify(state.organizationMember));
                state.organizationMemberLoadingStatus = "loaded";

                setStatus(state, "loaded");
            }
            else {
                state.organizationMemberLoadingStatus = "error";

                setStatus(state, "loadError");
            }
        });

        builder.addCase(thunks.getOrganizationMember.rejected, (state) => {
            state.organizationMemberLoadingStatus = "error";

            setStatus(state, "loadError");
        });

        builder.addCase(thunks.getRoles.pending, (state) => {
            state.rolesLoadingStatus = "loading";

            setStatus(state, "loading");
        });

        builder.addCase(thunks.getRoles.fulfilled, (state, action) => {
            if (action.payload.status === "2xx") {
                state.allRoles = action.payload!.roles;
                state.rolesLoadingStatus = "loaded";

                setStatus(state, "loaded");
            }
            else {
                state.rolesLoadingStatus = "error";

                setStatus(state, "loadError");
            }
        });

        builder.addCase(thunks.getRoles.rejected, (state) => {
            state.rolesLoadingStatus = "error";
            
            setStatus(state, "loadError");
        });

        builder.addCase(thunks.updateOrganizationMember.pending, (state) => {
            setStatus(state, "saving");
        });

        builder.addCase(thunks.updateOrganizationMember.fulfilled, (state, action) => {
            setStatus(state, action.payload.status === "2xx" ? "saved" : "saveError");
        });

        builder.addCase(thunks.updateOrganizationMember.rejected, (state) => {
            setStatus(state, "saveError");
        });
    }
});

const setStatus = (state: Redux.Draft<SliceProps>, status: SliceProps["status"]) => {
    if (status === "loading"
        && (state.organizationMemberLoadingStatus === "error"
            || state.rolesLoadingStatus === "error")) {

        return;
    }

    if (status === "loaded"
        && (state.organizationMemberLoadingStatus !== "loaded"
            || state.rolesLoadingStatus !== "loaded")) {

        return;
    }

    state.status = status;
}

const getEditingUserLinesOfBusiness = (userClaims: UserClaim[]): string[] => userClaims!
    .filter(claim => claim.type === "https://auth.directsupply.com/lines_of_business")
    .map(claim => claim.value);

    export const selectHasChanges = createSelector(
        (state: RootState) => state.editOrganizationMember,
        (state) => {
            return state?.organizationMember
                && JSON.stringify(state.organizationMember) !== JSON.stringify(state.originalOrganizationMember);
        }
    );
    
    export const selectChangesAreValid = createSelector(
    (state: RootState) => state.editOrganizationMember.organizationMember,
    (organizationMember) => {
        return organizationMember && organizationMember.roles.length > 0;
    }
);
