import {
    Button,
    Card,
    DsxLoadingSpinner,
    MdAdd,
    MdArrowBack,
    MdClose,
    MdError,
    MultiSearchSelectField,
    StandaloneIcon,
    Text,
    TextInput,
    useToast
} from "@dsx/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../../auth/auth-context";
import { getStore } from "../../../../store";
import { PageProps, StoreState, useSlice } from "../../../../util/page";
import siteStyles from "../../../site.module.css";
import { slice, thunks } from "./add-organization-invitation.slice";


export function AddOrganizationInvitation(props: PageProps<"addInvitation">) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const popToast = useToast();
    const { organizationId } = useParams<{ organizationId: string }>();

    const [email, setEmail] = React.useState("");
    const [roles, setRoles] = React.useState<string[]>([]);
    const [emailBlurred, setEmailBlurred] = React.useState(false);
    const [rolesBlurred, setRolesBlurred] = React.useState(false);

    const invitation = {
        inviteeEmail: email,
        roles: roles,
    };

    const availableRoles = useSelector((state: StoreState) => state.addInvitation.roles || []);

    const emailIsValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    const invitationIsValid = emailIsValid && roles.length > 0;

    React.useEffect(() => {
        dispatch(thunks.getUserRoles());
    }, [dispatch]);


    const reset = () => {
        setEmail("");
        setEmailBlurred(false);
        setRoles([]);
        setRolesBlurred(false);
        dispatch(slice.actions.setStatus("initial"));
    };

    const addOrganizationInvitation = async () => {
        if (!organizationId) 
            return;

        try {
            await dispatch(thunks.addOrganizationInvitation({ organizationId, invitation }));

            const status = getStore().getState().addInvitation.status;
            
            if (status === "saved") {
                popToast(
                    <span>
                        Invitation sent to <b>{email}</b>.
                    </span>, {
                        title: "Invitation sent",
                        variant: "success",
                        position: "top-center",
                        autoClose: 5000,
                    }
                );

                reset();
                return;
            }
        }
        catch (error) {
            console.log(error);
        }

        popToast(
            <span>
                An error occurred while sending invitation to <b>{email}</b>. Please try again.
            </span>, {
                title: "Error sending invitation",
                variant: "danger",
                position: "top-center",
                autoClose: false,
            }
        );
    };

    return (
        <div className={siteStyles["block-container"]}>
            <Card>
                <Text variant="headline-1">Invite Users</Text>
                <br></br>
                <Text>
                    Please fill in the details below to invite users to your organization.
                </Text>
            </Card>
            <Card>
                {props.status === "loading" && (
                    <div className={siteStyles["loading-container"]}>
                        <StandaloneIcon
                            icon={DsxLoadingSpinner}
                            title={"Loading member..."}
                            size="size-1100"
                        />
                    </div>
                )}
                {props.status === "loadError" && (
                    <div className={siteStyles["icon-block-container"]}>
                        <div className={siteStyles["centered-container"]}>
                            <StandaloneIcon
                                icon={MdError}
                                title="Error loading user list"
                                size="size-1100"
                            />
                        </div>
                        <div className={siteStyles["centered-container"]}>
                            <Text variant="body">Error loading user list</Text>
                        </div>
                    </div>
                )}
                {props.status !== "loading" && props.status !== "loadError" && (
                    <div className={siteStyles["card-content"]}>
                        <div>
                            <label htmlFor="email">Email</label>
                            <TextInput
                                name="email"
                                required={true}
                                variant={emailBlurred && !emailIsValid ? "danger" : "default"}
                                readOnly={props.status === "saving"}
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                onBlur={() => setEmailBlurred(true)}
                            />
                        </div>
                        <div>
                            <label htmlFor="roles">Roles</label>
                            <MultiSearchSelectField
                                name="roles"
                                required={true}
                                variant={rolesBlurred && roles.length === 0 ? "danger" : "default"}
                                value={roles}
                                options={availableRoles.map(role => ({ value: role, label: role }))}
                                onChange={selectedValues => setRoles(selectedValues)}
                                onBlur={() => setRolesBlurred(true)}
                                label={undefined}
                            />
                        </div>
                    </div>
                )}
                <div className={siteStyles["button-container"]}>
                    <div>
                        <Button
                            variant="primary"
                            icon={MdAdd}
                            disabled={!invitationIsValid || props.status === "saving"}
                            onClick={addOrganizationInvitation}
                            loadingState={props.status === "saving" ? "loading" : "initial"}
                        >
                            Invite User
                        </Button>
                        <Button
                            variant="ui-control"
                            icon={MdClose}
                            disabled={props.status === "saving"}
                            onClick={() => reset()}
                        >
                            Discard
                        </Button>
                    </div>
                    <div className={siteStyles["back-button"]}>
                        <Button
                            variant="ui-control"
                            icon={MdArrowBack}
                            disabled={props.status === "saving"}
                            onClick={() => navigate(-1)}
                        >
                            Back
                        </Button>
                    </div>
                </div>
            </Card>
        </div>
    );
}

export function AddOrganizationInvitationConnected() {
    const storeProps = useSlice("addInvitation");
    return <AddOrganizationInvitation {...storeProps} />;
}
